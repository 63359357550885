<template>
  <b-modal id="modalAddEdit" :title="title" no-close-on-backdrop @shown="init">
    <b-form @submit.prevent="save">
      <div class="form-group">
        <label for="name">{{$t('name')}}</label>
        <b-form-input v-model="banner.name" v-validate="'required|max:25'" name="name" id="name" :state="validateState('name')" />
        <span class="error-inputs">{{ errors.first('name') }}</span>
      </div>
      <div class="form-group">
        <label for="link">Link</label>
        <b-form-input v-model="banner.link" v-validate="'required|max:250'" name="link" id="link" :state="validateState('link')" />
        <span class="error-inputs">{{ errors.first('link') }}</span>
      </div>
      <div class="form-group">
        <label for="order">{{$t('order')}}</label>
        <b-form-input v-model="banner.order" v-validate="'required|numeric|min_value:1'" name="order" id="order" :state="validateState('order')" type="number" />
        <span class="error-inputs">{{ errors.first('order') }}</span>
      </div>
      <div class="form-group">
        <b-form-checkbox v-model="banner.show" name="check-button" switch>{{ $t('show') }}</b-form-checkbox>
      </div>
    </b-form>
    <div class="form-group">
        <label for="file">{{ $t('findImage') }}</label>
        <div class="custom-file" :class="validateState('language') ? 'is-valid' : 'is-invalid'">
          <input type="file" ref="inputFile" class="custom-file-input" id="file" :lang="lang" @change="imagePreview($event)" v-validate="'image|ext:jpg,jpeg,png|size:1024'" accept=".png,.jpg,.jpeg" name="file" :data-vv-as="$t('image') | toLowerCase">
          <label class="custom-file-label" ref="lblFileName" for="file">{{ $t('findImage') }}</label>
        </div>
        <div class="w-100 text-center">
          <img v-if="imgPath" :src="imgPath" :alt="banner.name" class="img-fluid img-thumbnail mt-3">
        </div>
      </div>
    <div slot="modal-footer">
      <b-button @click="$root.$emit('bv::hide::modal', 'modalAddEdit')">{{$t('cancel')}}</b-button>
      <b-button variant="primary" @click="save" class="ml-3">{{$t('save')}}</b-button>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    banner: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      title: '',
      file: null,
      imgPath: ''
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console'
    ])
  },
  methods: {
    imagePreview (e) {
      const input = e.target  
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        _self.$refs.lblFileName.innerHTML = _self.file.name
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        _self.file = null
        _self.$refs.lblFileName.innerHTML =  _self.$t('findImage')
      }
    },
    init () {
      this.file = null
      this.$refs.lblFileName.innerHTML =  this.$t('findImage')
      this.imgPath = ''
      if (!this.banner.id) {
        this.title = this.$t('create')
      } else {
        this.title = this.$t('edit')
        this.imgPath = this.banner.image
        this.banner.show = this.banner.active === 1 ? true : false
      }
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = new FormData()
          payload.append('name', this.banner.name)
          payload.append('order', this.banner.order)
          payload.append('link', this.banner.link)
          payload.append('show', this.banner.show ? 1 : 0)
          if (this.file) {
            payload.append('image', this.file)
          }
          const tournamentId = this.$route.params.tournamentId
          if (!this.banner.id) {
            const path = `auth/${this.lang}/${this.console}/tournaments/${tournamentId}/banners/store`
            this.$axios.post(path, payload).then((response) => {
              this.$emit('addBanner', response.data.data)
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$root.$emit('bv::hide::modal', 'modalAddEdit')
            })
          } else {
            const path = `auth/${this.lang}/${this.console}/tournaments/${tournamentId}/banners/${this.banner.id}/update`
            this.$axios.post(path, payload).then((response) => {
              this.$emit('editBanner', response.data.data)
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$root.$emit('bv::hide::modal', 'modalAddEdit')
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
